import RobiPhoto from '../src/assets/images/team_members/processed/RobiTacutu_processed.png';
import DmitriPhoto from '../src/assets/images/team_members/processed/DmitriToren_processed.jpg';

const DATA = {
    "pallette": {
        primary: "#5CDB95",
        primary_light: "#379683",
        secondary: "#05386B",
        secondary_light: "#8EE4AF",
        contrast: "#EDF5E1"
    },
    "SEO": {
        "title": "Biolab Studios",
        "description": "Social media content tailored to biotech. By biologists for biologists."
    },
    "Header": {
        "menuItems": [
            {
                "path": "home",
                "label": "Biolab Studios",
            },
            {
                "path": "whyus",
                "label": "Why us",
            },
            {
                "path": "about",
                "label": "About",
            },
            {
                "path": "portfolio",
                "label": "Portfolio"
            },
            {
                "path": "contact",
                "label": "Contact",
            },
        ]
    },
    "Banner": {
        "Motto": "Advertising for Biologists by Biologists!"
    },
    "About": {
        "title": "Expand your social media presence with our unique approach",
        "data": [
            {
                "id": 1,
                "icon": DmitriPhoto,
                "title": "Dmitri Toren, PhD",
                "role": "Scientist, Entrepreneur",
                "position": "Co-founder",
            },
            {
                "id": 2,
                "icon": RobiPhoto,
                "title": "Robi Tacutu, PhD",
                "role": "Scientist, Entrepreneur",
                "position": "Co-founder",
            },
        ]
    },
    "Portfolio": {
        "section_title": "Portfolio",
        "text": "Grow your social media presence with our unique approach"
    },
    "Contact": {
        "title": "Connect with us"
    },
    "Footer": {}
}

export default DATA
